export default async function ({ $axios, route, store }) {
  try {
    const response = await $axios.$get('/api/product/v4/widgets')
    const widgets = response.widgets

    const currentRoute = route.name

    const activeWidgets = widgets?.filter(
      widget => widget.pages.filter(page => page.indexOf(currentRoute) > -1)
    )

    store.commit('WidgetsStore/SET_WIDGETS', activeWidgets)
  } catch (error) {
    console.error(error)
  }

}
